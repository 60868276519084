// import React, { Component } from "react";
// import Slider from "react-slick";
// import "../../assets/css/style.css";
// import Image from "../../assets/Image";

// export default class SwipeToSlide extends Component {
//   render() {
//     const settings = {
//       className: "center",
//       centerMode: true,
//       infinite: true,
//       centerPadding: "60px",
//       autoplay: true,
//       slidesToShow: 4,
//       speed: 500,
//       rows: 1,
//       slidesPerRow: 1,

//       responsive: [
//         {
//           breakpoint: 1024,
//           settings: {
//             slidesToShow: 2,
//             slidesPerRow: 1,
//           },
//         },
//         {
//           breakpoint: 600,
//           settings: {
//             slidesToShow: 1,
//             slidesPerRow: 1,
//           },
//         },
//         {
//           breakpoint: 480,
//           settings: {
//             centerPadding: "20px",
//             slidesToShow: 1,
//             slidesPerRow: 1,
//           },
//         },
//       ],
//     };
//     return (
//       <div className="container">
//         <Slider {...settings}>
//           <div className="sponser-img">
//             <img src={Image.clientOne} />
//           </div>
//           <div className="sponser-img">
//             <img src={Image.clientTwo} />
//           </div>
//           <div className="sponser-img">
//             <img src={Image.clientThree} />
//           </div>
//           <div className="sponser-img">
//             <img src={Image.clientFour} />
//           </div>
//           <div className="sponser-img">
//             <img src={Image.clientFive} />
//           </div>
//           <div className="sponser-img">
//             <img src={Image.clientSix} />
//           </div>
//           <div className="sponser-img">
//             <img src={Image.clientSeven} />
//           </div>
//           <div className="sponser-img">
//             <img src={Image.clientEight} />
//           </div>
//           <div className="sponser-img">
//             <img src={Image.clientNine} />
//           </div>
//           {/* <div className="sponser-img">
//             <img src={Image.clientTen} />
//           </div> */}
//           <div className="sponser-img">
//             <img src={Image.clientEleven} />
//           </div>
//           <div className="sponser-img">
//             <img src={Image.clientTwelve} />
//           </div>

//           <div className="sponser-img">
//             <img src={Image.clientOne} />
//           </div>
//           <div className="sponser-img">
//             <img src={Image.clientTwo} />
//           </div>
//           <div className="sponser-img">
//             <img src={Image.clientThree} />
//           </div>
//           <div className="sponser-img">
//             <img src={Image.clientFour} />
//           </div>
//           <div className="sponser-img">
//             <img src={Image.clientFive} />
//           </div>
//           <div className="sponser-img">
//             <img src={Image.clientSix} />
//           </div>
//           <div className="sponser-img">
//             <img src={Image.clientSeven} />
//           </div>
//           <div className="sponser-img">
//             <img src={Image.clientEight} />
//           </div>
//           <div className="sponser-img">
//             <img src={Image.clientNine} />
//           </div>
//           {/* <div className="sponser-img">
//             <img src={Image.clientTen} />
//           </div> */}
//           <div className="sponser-img">
//             <img src={Image.clientEleven} />
//           </div>
//           <div className="sponser-img">
//             <img src={Image.clientTwelve} />
//           </div>
//         </Slider>
//       </div>
//     );
//   }
// }

import React, { Component } from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "../../assets/css/style.css";
import Image from "../../assets/Image";

export default class SwipeToSlide extends Component {
  render() {
    const slideImages = [
      Image.clientOne,
      Image.clientTwo,
      Image.clientThree,
      Image.clientFour,
      Image.clientFive,
      Image.clientSix,
      Image.clientSeven,
      Image.clientEight,
      Image.clientNine,
      Image.clientEleven,
      Image.clientTwelve,
    ];

    const properties = {
      duration: 2000,
      transitionDuration: 500,
      infinite: true,
      // indicators: true,
      arrows: true,
      className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "60px",
            autoplay: true,
            slidesToShow: 4,
            speed: 500,
            rows: 1,
            slidesPerRow: 1,
           

    };

    return (
      <div className="container">
        <Slide {...properties}>
          {slideImages.map((each, index) => (
            <div key={index} className="each-slide">
              <div className="sponser-img">
                <img src={each} />
              </div>
            </div>
          ))}
        </Slide>
      </div>
    );
  }
}
