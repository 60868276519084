import Image from "../../assets/Image";
const imgs = [
  {
    id: 1,
    author: "Do It Center",
    tag: "Android",
    src: Image.PortfolioImgOne,
    tag_obj: ["Android", "iOS"],
    images: [
      {
        id: 1,
        image: Image.innerPortfolio1,
        tag: "Android",
        author: "Do It Center",
      },
    ],
    content:
      "Our app give you the accessibility to see which items are available at store , we have features like  Wishlist , Add to Cart , Place Order , Purchase History and  See your loyalty points quickly. We have added app for pickup of your orders too.",
    technologies: "Ionic",
    Android_url:
      "https://play.google.com/store/apps/details?id=com.doitcenter&hl=en",
    Ios_url: "https://apps.apple.com/in/app/doitcenter-aruba/id1478265147",
    Industry: "Ecommerce, grocery",
    Platform: "iOS, Android",
  },
  {
    id: 2,
    author: "Binc",
    tag: "Android",
    src: Image.PortfolioImgTwo,
    tag_obj: ["Android", "iOS"],
    images: [
      {
        id: 1,
        image: Image.innerPortfolio2,
        author: "Binc",
        tag: "Android",
      },
    ],
    content:
      "A one stop solution for brands and influencers to connect with each other, match instantly, run seamless campaigns and grow together. So swipe right to understand the latest disruption in this space.",
    technologies: "Flutter",
    Android_url: "https://www.binc.co.in/",
    Ios_url: "https://www.binc.co.in/",
    Industry: "Social Media",
    Platform: "iOS Android .NET",
  },
  {
    id: 3,
    author: "Mentorbooth",
    tag: "Android",
    src: Image.PortfolioImgthree,
    tag_obj: ["Android", "iOS"],
    images: [
      {
        id: 1,
        image: Image.innerPortfolio3,
        author: "Mentorbooth",
        tag: "Android",
      },
    ],
    content:
      "Discover your next great read on our upcoming app Enjoy access to thousands of book summaries and audio content.",
    technologies: "Flutter, Nodejs, Google Firebase",
    Android_url:
      "https://play.google.com/store/apps/details?id=com.mentorbooth",
    Ios_url: "https://apps.apple.com/us/app/mentor-booth/id1612941211",
    Industry: "Learning",
    Platform: "iOS Android",
  },
  {
    id: 4,
    author: "Mypeople Vote",
    tag: "iOS",
    src: Image.PortfolioImgfour,
    tag_obj: ["Android", "iOS"],
    images: [
      {
        id: 1,
        image: Image.innerPortfolio4,
        author: "Mypeople Vote",
        tag: "iOS",
      },
    ],
    content:
      "The non-partisan My People Vote app manages campaign databases from beginning to end. It is easy to use and understand for all ages. This program was designed with the input of candidates, managers, political scientists, political analysts, pollsters, phone bankers, and poll watchers. Nothing has been left out.",
    technologies: "iOS, Android",
    Android_url:
      "https://play.google.com/store/apps/details?id=com.app.mypeoplevote",
    Ios_url: "https://apps.apple.com/us/app/my-people-vote/id1350076661",
    Industry: "Politics",
    Platform: "iOS, Android",
  },
  {
    id: 5,
    author: "FreightSnap",
    tag: "iOS",
    src: Image.PortfolioImgfive,
    tag_obj: ["Android", "iOS"],
    images: [
      {
        id: 1,
        image: Image.innerPortfolio5,
        author: "FreightSnap",
        tag: "iOS",
      },
    ],
    content:
      "The main function of this application is to Trigger your FreightSnap device with the touch of a button and without leaving your seat.",
    technologies: "AR",
    Android_url:
      "https://play.google.com/store/apps/details?id=com.freightsnap.MainTriggering",
    Ios_url:
      "https://apps.apple.com/in/app/freightsnap-utility/id1612501838",
    Industry: "AR",
    Platform: "iOS, Android",
  },

  {
    id: 6,
    author: "Dine",
    tag: "Android",
    src: Image.PortfolioImgsix,
    tag_obj: ["Android", "iOS"],
    images: [
      {
        id: 1,
        image: Image.innerPortfolio6,
        author: "Dine",
        tag: "Android",
      },
    ],
    content:
      "Dine combines food ordering, loyalty points, chat rooms, news and offers from your favorite restaurant all in one app. We even made food order different than the usual. You can Pre-order for dine-in purposes so that your order can be ready for you on a pre-booked table at a time of your choice or ready for you at the restaurant counter for pick up. Even better you can order from your own mobile phone or computer at the restaurant as if having your own personal waiter on your phone. No more waiting time, no more frustrations, and fewer chances of any mistakes.",
    technologies: "PHP CI",
    Android_url: "https://play.google.com/store/apps/details?id=com.app.dine",
    Ios_url: "https://apps.apple.com/us/app/dine-food-more/id1463034778?ls=1",
    Industry: "Food/Restaurants",
    Platform: "iOS Android",
  },
  {
    id: 7,
    author: "My Clinic",
    tag: "iOS",
    src: Image.PortfolioImgsevan,
    tag_obj: ["Android"],
    images: [
      {
        id: 1,
        image: Image.innerPortfolio7,
        author: "My Clinic",
        tag: "iOS",
      },
    ],
    content:
      "Clinics Kw is a platform connecting clients with all kind of clinics. You can: view clinics, check availability, book appointments, read reviews, check prices, view services, and much more!",
    technologies: "Android",
    Android_url:
      "https://play.google.com/store/apps/details?id=com.app.myclinic",
    // Ios_url: "https://play.google.com/store/apps/details?id=com.app.myclinic",
    Industry: "Clinc",
    Platform: "Android",
  },
  {
    id: 8,
    author: "Jewelers' Assist",
    tag: "iOS",
    src: Image.PortfolioImgeight,
    tag_obj: ["Android", "iOS"],
    images: [
      {
        id: 1,
        image: Image.innerPortfolio8,
        author: "Jewelers",
        tag: "iOS",
      },
    ],
    content:
      "All the Essential Tools for Jewelry Production Efficiency. Be one step ahead! You don’t need to be a PRO, Jewelers’ Assist will save you time and money by these user friendly features: Weight & Casting Cost Calculator Enter the volume of your CAD design to calculate the weight and the approximate cost of your piece in major precious metals (silver, 10K, 14K, 18K, Platinum) -Easily transfer your casting cost to “Jeweler’s Ledger”",
    technologies: "iOS, Android",
    Android_url:
      "https://play.google.com/store/apps/details?id=com.app.goldcalculationfree",
    Ios_url: "https://apps.apple.com/us/app/jewelers-scale/id1367390992?ls=1",
    Industry: "jwellery",
    Platform: "iOS, Android",
  },
  {
    id: 9,
    author: "The Miracle Of Saint Joseph",
    tag: "Web Development",
    src: Image.webPortfolio_Image1,
    tag_obj: ["Web Development"],
    images: [
      {
        id: 1,
        image: Image.webPortfolio_Image1,
        author: "The Miracle Of Saint Joseph",
        tag: "Web Development",
      },
    ],
    content:
      "For the first time in history, all of Heaven has spoken directly to the world, giving us The Miracle of Saint Joseph through a True Mystic, Frances Marie Klug.",
    technologies: "wordpress",
    url: "https://www.themiracleofstjoseph.org/",
    Industry: "Spiritual",
    Platform: "Website",
  },
  {
    id: 10,
    author: "Lumineux",
    tag: "Web Development",
    src: Image.webPortfolio_Image2,
    tag_obj: ["Web Development"],
    images: [
      {
        id: 1,
        image: Image.webPortfolio_Image2,
        author: "Lumineux",
        tag: "Web Development",
      },
    ],
    content: "Experience the Art of Natural Skin Transformation.",
    technologies: "wordpress",
    url: "https://lumineuxoc.com/",
    Industry: "Clinic",
    Platform: "Website",
  },
  {
    id: 11,
    author: "MentorBooth",
    tag: "Web Development",
    src: Image.webPortfolio_Image3,
    tag_obj: ["Web Development"],
    images: [
      {
        id: 1,
        image: Image.webPortfolio_Image3,
        author: "MentorBooth",
        tag: "Web Development",
      },
    ],
    content:
      "Discover your next great read on our upcoming app Enjoy access to thousands of book summaries and audio content.",
    technologies: "wordpress",
    url: "https://mentorbooth.com/",
    Industry: "Learning",
    Platform: "Website",
  },
  {
    id: 12,
    author: "In Style Nails",
    tag: "Web Development",
    src: Image.webPortfolio_Image4,
    tag_obj: ["Web Development"],
    images: [
      {
        id: 1,
        image: Image.webPortfolio_Image4,
        author: "In Style Nails",
        tag: "Web Development",
      },
    ],
    content:
      "We have a large team of long-term talented professionals. Focused on quality and pristine environment allows you to relax and enjoy getting pampered. Remain worry free knowing all our tools and equipment are deep cleaned regularly.",
    technologies: "wordpress",
    url: "https://instyledavenport.com/",
    Industry: "Beauty",
    Platform: "Website",
  },
  {
    id: 13,
    author: "Tunity",
    tag: "Web Development",
    src: Image.webPortfolio_Image5,
    tag_obj: ["Web Development"],
    images: [
      {
        id: 1,
        image: Image.webPortfolio_Image5,
        author: "Tunity",
        tag: "Web Development",
      },
    ],
    content:
      "Tunity developed the first mobile application of its kind which allows users to hear live audio from muted televisions.",
    technologies: "wordpress",
    url: "https://www.tunity.com/",
    Industry: "Learning",
    Platform: "Website",
  },
  {
    id: 14,
    author: "Fiduciary Architects",
    tag: "Web Development",
    src: Image.webPortfolio_Image6,
    tag_obj: ["Web Development"],
    images: [
      {
        id: 1,
        image: Image.webPortfolio_Image6,
        author: "Fiduciary Architects",
        tag: "Web Development",
      },
    ],
    content: "Freedom to Customize your Client’s Fiduciary Experience",
    technologies: "wordpress",
    url: "https://www.fiduciaryarchitects.com/advisors/",
    Industry: "Account",
    Platform: "Website",
  },
  {
    id: 15,
    author: "High Beam",
    tag: "Web Development",
    src: Image.webPortfolio_Image7,
    tag_obj: ["Web Development"],
    images: [
      {
        id: 1,
        image: Image.webPortfolio_Image7,
        author: "High Beam",
        tag: "Web Development",
      },
    ],
    content: "Freedom to Customize your Client’s Fiduciary Experience",
    technologies: "wordpress",
    url: "https://www.highbeamshoes.com/",
    Industry: " Shoes",
    Platform: "Website",
  },
  {
    id: 16,
    author: "Trusted Mortgage Capital",
    tag: "Web Development",
    src: Image.webPortfolio_Image8,
    tag_obj: ["Web Development"],
    images: [
      {
        id: 1,
        image: Image.webPortfolio_Image8,
        author: "Trusted Mortgage Capital",
        tag: "Web Development",
      },
    ],
    content:
      "Choose the loan that works for you! Tap into your home’s equity & get Cash-out",
    technologies: "wordpress",
    url: "https://www.trustedmortgagecapital.com/",
    Industry: "Loan",
    Platform: "Website",
  },
  {
    id: 17,
    author: "MLO",
    tag: "Web Development",
    src: Image.webPortfolio_Image9,
    tag_obj: ["Web Development"],
    images: [
      {
        id: 1,
        image: Image.webPortfolio_Image9,
        author: "MLO",
        tag: "Web Development",
      },
    ],
    content: "Convenient & FlexibleTO YOUR BUSY SCHEDULE",
    technologies: "wordpress",
    url: "https://mloprotraining.com/home",
    Industry: "Learning",
    Platform: "Website",
  },
  {
    id: 18,
    author: "Granville on the water",
    tag: "Web Development",
    src: Image.webPortfolio_Image10,
    tag_obj: ["Web Development"],
    images: [
      {
        id: 1,
        image: Image.webPortfolio_Image10,
        author: "Granville on the waterO",
        tag: "Web Development",
      },
    ],
    content:
      "Owning a home in Prince Edward Island allows you to keep in touch with nature, with the red beach sands, with the breezes from the ocean, and with the best of the people and activities found on this island paradise.",
    technologies: "wordpress",
    url: "https://www.granvilleonthewater.ca/",
    Industry: "Learning",
    Platform: "Website",
  },

  {
    id: 19,
    author: "LSPS solution",
    tag: "Web Development",
    src: Image.portfoliolsps,
    tag_obj: ["Web Development"],
    images: [
      {
        id: 1,
        image: Image.portfoliolsps,
        author: "Granville on the waterO",
        tag: "Web Development",
      },
    ],
    content:
      "LSPS Solutions is a consulting firm, focusing on asset management, infrastructure maintenance, planning and design, and regulatory compliance.",
    technologies: "wordpress",
    url: "https://www.lspssolutions.com/",
    Industry: "Infrastructure",
    Platform: "Website",
  },

  {
    id: 20,
    author: "My People",
    tag: "Web Development",
    src: Image.webportfolio14,
    tag_obj: ["Web Development"],
    images: [
      {
        id: 1,
        image: Image.webportfolio14,
        author: "mypeople",
        tag: "Web Development",
      },
    ],
    content:
      "My People  subscriptions have been developed by public administration & political science experts, academics, and practitioners.",
    technologies: "php",
    url: "https://lapublicopinion.com/",
    Industry: "public administration",
    Platform: "Website",
  },

  {
    id: 21,
    author: "carexpertz",
    tag: "Web Development",
    src: Image.webportfolio15,
    tag_obj: ["Web Development"],
    images: [
      {
        id: 1,
        image: Image.webportfolio15,
        author: "mypeople",
        tag: "Web Development",
      },
    ],
    content:
      "At Care Xpertz, we have an outstanding team of rigorously trained professional caregivers who jointly work around the clock offering the best services.",
    technologies: "wordpress",
    url: "https://carexpertz.com/",
    Industry: "public administration",
    Platform: "Website",
  },
  {
    id: 22,
    author: "DB Academics",
    tag: "Web Development",
    src: Image.webPortfolio_Image12,
    tag_obj: ["Web Development"],
    images: [
      {
        id: 1,
        image: Image.webPortfolio_Image12,
        author: "DB Academics",
        tag: "Web Development",
      },
    ],
    content:
      "We are dedicated to improving students’ academic performance as well as their confidence! Empowering students to believe in themselves, overcome obstacles and adversity and strive to reach their goals. Our teachers are skilled educators who relate well with students of all ages and backgrounds.",
    technologies: "wordpress",
    url: "https://www.dbacademics.com/",
    Industry: "Learning",
    Platform: "Website",
  },
  {
    id: 23,
    author: "Do It Society",
    tag: "Android",
    src: Image.PortfolioImgnine,
    tag_obj: ["Android", "iOS"],
    images: [
      {
        id: 1,
        image: Image.innerPortfolio9,
        author: "mypeople",
        tag: "Android",
      },
    ],
    content:
      "The United Merchants Association of Aruba (d.b.a. Comerciante Uni) is an organization of local private sector merchants. It aims to promote the interests of its members, its customers and employees and the sustainanle socio-economic development of Aruba.",
    technologies: "Android, iOS",
    Android_url:
      "https://play.google.com/store/apps/details?id=com.app.doitsociety",
    Ios_url:
      "https://apps.apple.com/pl/app/cua/id1539935103",
    Industry: "public administration",
    Platform: "Android, iOS",
  },
  {
    id: 24,
    author: "Flexco",
    tag: "Web Development",
    src: Image.webPortfolio_Image13,
    tag_obj: ["Web Development"],
    images: [
      {
        id: 1,
        image: Image.webPortfolio_Image13,
        author: "Flexco",
        tag: "Web Development",
      },
    ],
    content:
      "Flexco Is Dedicated to the Customer and Is Aggressively Investing to Better Serve Aerospace and Defense.",
    technologies: "wordpress",
    url: "https://www.flexcoinc.com/",
    Industry: "Aircraft",
    Platform: "Website",
  },

  {
    id: 25,
    author: "Theluxevoyager",
    tag: "Web Development",
    src: Image.webPortfolio_Image16,
    tag_obj: ["Web Development"],
    images: [
      {
        id: 1,
        image: Image.webPortfolio_Image16,
        author: "Theluxevoyager",
        tag: "Web Development",
      },
    ],
    content:
      "Our core value is to delight our clients by designing exceptional individually crafted & tailor made bespoke luxury travel experiences offering the highest level of personalized service, resulting in cherished lifetime memories.",
    technologies: "wordpress",
    url: "https://www.theluxevoyager.com/",
    Industry: "Hotel booking",
    Platform: "Website",
  },

  {
    id: 26,
    author: "Gelda Namkeen",
    tag: "Web Development",
    src: Image.webPortfolio_Image18,
    tag_obj: ["Web Development"],
    images: [
      { 
        id: 1,
        image: Image.webPortfolio_Image18,
        author: "Gelda Namkeen",
        tag: "Web Development",
      },
    ],
    content:
      "Gelda Namkeen Bhandar, Ratlam is a top player Shop in Ratlam. This well-known establishment acts as a one-stop destination servicing customers both local and from other parts of Ratlam.",
    technologies: "wordpress",
    url: "https://www.geldanamkeenratlam.com/",
    Industry: "food",
    Platform: "Website",
  },

  {
    id: 27,
    author: "Southern California Surrogacy",
    tag: "Web Development",
    src: Image.webPortfolio_Image19,
    tag_obj: ["Web Development"],
    images: [
      { 
        id: 1,
        image: Image.webPortfolio_Image19,
        author: "Southern California Surrogacy",
        tag: "Web Development",
      },
    ],
    content:
      "Welcome to Southern California Surrogacy, your trusted partner on the incredible journey to parenthood through surrogacy. Our mission is simple yet profound: connecting parents in need with quality surrogates.",
    technologies: "wordpress",
    url: "https://www.southerncaliforniasurrogacy.com/",
    Industry: "Medical",
    Platform: "Website",
  },
  // {
  //   id: 28,
  //   author: "rajalemporium",
  //   tag: "Web Development",
  //   src: Image.webPortfolio_Image20,
  //   tag_obj: ["Web Development"],
  //   images: [
  //     { 
  //       id: 1,
  //       image: Image.webPortfolio_Image20,
  //       author: "rajalemporium",
  //       tag: "Web Development",
  //     },
  //   ],
  //   content:
  //     "Onlinee E-commerce website for Home Furnishing ",
  //   technologies: "wordpress",
  //   url: "https://www.rajalemporium.com/",
  //   Industry: "Home Furnishing",
  //   Platform: "Website",
  // },

  {
    id: 29,
    author: "Flo-Trace",
    tag: "Web Development",
    src: Image.webPortfolio_Image21,
    tag_obj: ["Web Development"],
    images: [
      { 
        id: 1,
        image: Image.webPortfolio_Image21,
        author: "Flo-Trace",
        tag: "Web Development",
      },
    ],
    content:
      "The Texas Commission on Environmental Quality requires all community water systems to maintain a cross-connection control program for protection of the system that distributes drinking water to your home or business ",
    technologies: "wordpress",
    url: "https://www.flo-trace.com/",
    Industry: "water systems",
    Platform: "Website",
  },
  {
    id: 30,
    author: "Nidhi Creation",
    tag: "Web Development",
    src: Image.webPortfolio_Image22,
    tag_obj: ["Web Development"],
    images: [
      { 
        id: 1,
        image: Image.webPortfolio_Image22,
        author: "Nidhi Creation",
        tag: "Web Development",
      },
    ],
    content:
      " Explore a curated collection featuring Engagement Ring Trays, Everyday Luxury, Purses, Festive Essentials, , Suits, Tissue, Velvet, Wedding Essentials, and more. Find everything you need to elevate your celebrations and home decor with style and sophistication.",
    technologies: "wordpress",
    url: "https://nidhicreations.co.in/",
    Industry: "Hand crafts",
    Platform: "Website",
  },
  {
    id: 31,
    author: " Rajal Emporium",
    tag: "Web Development",
    src: Image.webPortfolio_Image23,
    tag_obj: ["Web Development"],
    images: [
      { 
        id: 1,
        image: Image.webPortfolio_Image23,
        author: "Rajal Emporium",
        tag: "Unique & Comfortable Bedsheet",
      },
    ],
    content:
      "Transform Your Space with Elegant Comfort: Discover Our Exclusive Collection of Bedsheets in 100% Cotton, Sofa Covers, Pillowcases and we have a lot more things. Unique Designs to Elevate Your Home Decor!",
    technologies: "wordpress",
    url: "https://rajalemporium.com/",
    Industry: "Unique & Comfortable Bedsheet",
    Platform: "Website",
  },
  {
    id: 32,
    author: "Shree Salasar Agency",
    tag: "Web Development",
    src: Image.webPortfolio_Image24,
    tag_obj: ["Web Development"],
    images: [
      { 
        id: 1,
        image: Image.webPortfolio_Image24,
        author: "Shree Salasar Agency",
        tag: "They sell all kinds of fireworks",
      },
    ],
    content:
      "Celebrate every occasion with a burst of colors and joy. We offer a wide variety of fireworks, including traditional Anars, vibrant Chakris, dazzling Colorful Chakris, and thrilling Sky Rockets that light up the night sky.Explore our collection of fireworks and bring excitement, fun, and sparkles to your celebrations!",
    technologies: "wordpress",
    url: "https://shreesalasaragency.com/",
    Industry: "They sell all kinds of fireworks",
    Platform: "Website",
  },

  {
    id: 33,
    author: "Little big swim school",
    tag: "Web Development",
    src: Image.webPortfolio_Image26,
    tag_obj: ["Web Development"],
    images: [
      { 
        id: 1,
        image: Image.webPortfolio_Image26,
        author: "Little big swim school",
        tag: "A beautiful 25m salt water pool open to the public for swim fitness",
      },
    ],
    content:
      "Feeling successful and believing you are capable are two very important steps towards learning something new. I believe that there are TOO many opportunities for kids to be graded in their little lives – which is why at Little Big we don’t openly grade our swimmers; we don’t give report cards and we ALWAYS find something genuine to praise our swimmers for. Praise should outweigh correction, so correction doesn’t feel like criticism.",
    technologies: "wordpress",
    url: "https://www.littlebigswim.com.au/",
    Industry: "A beautiful 25m salt water pool open to the public for swim fitness",
    Platform: "Website",
  },


  // {
  //   id: 34,
  //   author: "Mangalam Network",
  //   tag: "Web Development",
  //   src: Image.webPortfolio_Image25,
  //   tag_obj: ["Web Development"],
  //   images: [
  //     { 
  //       id: 1,
  //       image: Image.webPortfolio_Image25,
  //       author: "Mangalam Network",
  //       tag: "They sell all kinds of fireworks",
  //     },
  //   ],
  //   content:
  //     " ",
  //   technologies: "wordpress",
  //   url: "https://shreesalasaragency.com/",
  //   Industry: "They sell all kinds of fireworks",
  //   Platform: "Website",
  // },

];

export default imgs;
